export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#fff', '#fff'],
        home_question: ['#fe2a54', '#fe2a54'],
        applausometro: ['rgba(229,99,74,0)', 'rgba(229,68,138,0)'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#EA4D95', '#E76E51'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#fff',
    secondary: '#263238',
    accent: '#EA4D95',
    text: '#25f4ee',
    background:"#000000",
    poll:{
        default:"#fff",
        answered:"#fe2a54",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}